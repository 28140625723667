import { createApp } from 'vue'
import App from './App.vue'
import i18n from "@/script/i18n";
import unoverlay from "unoverlay-vue";
import 'uno.css'
import '@/assets/style.css'
import VueMobileDetection from "vue-mobile-detection";
import { Drawer } from 'ant-design-vue';
const app = createApp(App);
window.app = app;
app.use(unoverlay).use(VueMobileDetection).use(Drawer).use(i18n).mount('#app')
