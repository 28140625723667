<template>
    <div class="wifi_icon">
        <div :style="{'backgroundColor': item}" :key="index" v-for="(item,index) in colorArr[props.level]"></div>
    </div>
</template>
<script setup>

import {ref} from "vue";

const props = defineProps({
    level: {
        type: Number,
        default: 4
    }
})
// const {level} = toRefs(props);
// const activeColor = computed(() => {
//     console.log(props.level)
//     return colorArr[props.level]
// });
const colorArr = ref([
    ['#00DF4A', '#00DF4A', '#00DF4A', '#00DF4A'],
    ['#ffa71f', '#ffa71f', '#ffa71f', '#898989'],
    ['#ffa71f', '#ffa71f', '#898989', '#898989'],
    ['#ff0000', '#898989', '#898989', '#898989'],
    ['#898989', '#898989', '#898989', '#898989'],
])

</script>
<style scoped>
.wifi_icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: end;
    background-color: #00000080;
    padding: 5px;
    border-radius: 50%;
}

.wifi_icon div {
    width: 3px;
    border-radius: 2px;
    background-color: #898989;
    bottom: 0;
    transform: translate(3px, -5px);
}

.wifi_icon div + div {
    margin-left: 4px;
}

.wifi_icon > div:nth-child(1) {
    height: 9px;
}

.wifi_icon > div:nth-child(2) {
    height: 12px;
}

.wifi_icon > div:nth-child(3) {
    height: 15px;
}

.wifi_icon > div:nth-child(4) {
    height: 18px;
}

</style>
