<template>
    <div class='lang flex items-center' @click='openSelect=true'>
        <div class='mr-[10px] w-fit text-white'>{{ activeLang?.name }}</div>
        <img src='@/assets/lang.svg' class='h-[36px]'/>
    </div>
    <a-drawer
        v-model:open='openSelect'
        class='custom-class'
        root-class-name='root-class-name'
        :root-style="{ color: 'blue' }"
        :style='{color: "#FFFFFF",backgroundColor: "#232939"}'
        title='Language'
        placement='right'
        width='250'
    >
        <p @click='changeLocale(item.code)' v-for='(item,index) in lanMap' :class='{active:item.code === locale}'
           :key="index"
           class='my-[10px] h-[29px] leading-[29px] pl-[10px] rounded-[5px] cursor-pointer hover:bg-[#4E5E8B] caret-transparent'
           rel="alternate" :hreflang="item.oMap">{{
                item.name
            }}</p>
    </a-drawer>
</template>
<script setup>
import {useI18n} from 'vue-i18n';
import {computed, getCurrentInstance, ref} from 'vue';

const openSelect = ref(false);
const activeLang = computed(() => lanMap.value.find(e => e.code === locale.value));
const lanMap = ref([
    {name: 'Tiếng Việt', code: 'vi_VN', oMap: 'vn'},
    {name: 'English', code: 'en_US', oMap: 'en'},
    {name: 'Malay', code: 'en_MAS', oMap: 'ms'},
    {name: '简体中文', code: 'zh_CN', oMap: 'zh-CN'},
    {name: '繁体中文', code: 'zh_TW', oMap: 'zh-TW'},
]);
const {locale} = useI18n();
const {proxy} = getCurrentInstance()

function addQueryParam(url, param, value) {
    // 检查URL是否已经有查询参数
    let separator = (url.indexOf('?') !== -1) ? '&' : '?';

    // 添加新的查询参数
    return url + separator + encodeURIComponent(param) + '=' + encodeURIComponent(value);
}

function changeLocale(lang) {
    console.log(lang);
    // locale.value = lang;
    if (window.location.href.indexOf('lang') !== -1) {
        window.location.href = window.location.href.replace(`lang=${proxy.$i18n.locale}`, `lang=${lang}`)
    } else {
        window.location.href = addQueryParam(window.location.href, 'lang', lang)
    }
}
</script>
<style>
.custom-class .ant-drawer-wrapper-body .ant-drawer-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
//border-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0)) 1 100%; //border-image-slice: 1;
}

.custom-class .ant-drawer-wrapper-body .ant-drawer-close {
    position: absolute !important;
    right: 0;
    color: #FFFFFF;
}

.custom-class .ant-drawer-wrapper-body .ant-drawer-title {
    color: #FFFFFF !important;
}

</style>
<style scoped>
.active {
    background-color: #F9A51A !important;
    color: #232939;
}
</style>
