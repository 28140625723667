<template>
    <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <MobilePage v-if="$isMobile()" @showDesc="visible=true"></MobilePage>
    <PcPage v-else @showDesc="visible=true"></PcPage>
    <van-popup v-model:show="visible" :confirm-button-text="$t('confirm')" closeable
               :class="$isMobile()?'w-[90vw]':'w-[40vw]'" class="bg-[#232939] rounded-[10px]">
        <div class="text-[14px] text-white px-[30px] pt-[46px] pb-[20px] max-h-[60vh] relative">
            <div class="flex justify-center items-center" :class="{'whitespace-pre pb-[20px]':!$isMobile()}">
                <img src="@/assets/d_left.svg"/>
                <div class="mx-[14px] text-[18px] text-center">{{ $t('word1') }}<span
                    class="text-[#F9A51A]">PP88</span></div>
                <img src="@/assets/d_left.svg" class="rotate-y-[180deg]"/>
            </div>
        </div>
        <div class="h-fit text-white px-[30px]">
            <div class="content">
                <div>{{ $t('word2') }}</div>
                <ul>
                    <li>{{ $t('word3') }}</li>
                    <li>{{ $t('word4') }}</li>
                    <li>{{ $t('word5') }}</li>
                </ul>
                <div>
                    {{ $t('word6') }}
                </div>
                <div>
                    {{ $t('word7') }}
                </div>
                <div class="text-right font-bold">{{ $t('team') }}</div>
            </div>
        </div>
    </van-popup>
    <div class="fixed " :class="$isMobile()?'bottom-[25px] right-[35px]':'bottom-[120px] right-[70px]'">
        <van-loading color="#1989fa" v-if="!isReady"/>
    </div>
    <LiveChatWidget
        :license="livechatIds[locale]"
        visibility="minimized"
    />
</template>

<script setup>
import {Popup as VanPopup, Loading as VanLoading} from "vant";
import 'vant/es/dialog/style';
import {ref} from "vue";
import MobilePage from "@/components/MobilePage.vue";
import PcPage from "@/components/PcPage.vue";
import {LiveChatWidget, useWidgetIsReady} from "@livechat/widget-vue";
import {useI18n} from "vue-i18n";

const isReady = useWidgetIsReady()
const {locale} = useI18n()
const visible = ref(false)
const livechatIds = {
    zh_CN: '15165273',
    zh_TW: '15165273',
    en_US: '16359324',
    en_MAS: '16359324',
    vi_VN: '15165414'
}
</script>
<style scoped>
.content div {
    margin: 20px 0;
}


.content ul li {
    list-style-type: disc;
    list-style-position: inside;
    margin: 20px 0;
}
</style>

